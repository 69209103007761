import React from "react";
import { Layout } from "antd";
import { Helmet } from "react-helmet";

import Header from "../components/common/header";
import CommonFooter from "../components/common/footer";
import GuideSider from "../components/guide/guide-sider";

import GuideContentPage from "../components/guide/content";

export default () => (
  <div>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Online Calendar Planner Guide &amp; Help</title>
      <meta
        name="description"
        content="Learn more about DayViewer online calendar planner to see how the organizer system, pro and team rooms work. If you are new to DayViewer you will find this very useful."
      />
    </Helmet>
    <Layout>
      <Header />
      <Layout style={{ minHeight: "100vh" }}>
        <GuideSider />
        <GuideContentPage />
      </Layout>
      <CommonFooter />
    </Layout>
  </div>
);
