import React from "react";

import { Grid, Row, Col, Card } from "antd";

const { Meta } = Card;

class VideoGrid extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Row gutter={8}>
          <Col xs={24} lg={8}>
            <Card
              hoverable
              cover={
                <video
                  preload="none"
                  controls
                  src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/videos/add-calendar-entry.mov"
                />
              }
            >
              <Meta
                title="Add Calendar Entry"
                description="An example of one way to add a calendar entry"
              />
            </Card>
          </Col>
          <Col xs={24} lg={8}>
            <Card
              hoverable
              cover={
                <video
                  preload="none"
                  controls
                  src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/videos/add-entry-week-view.mov"
                />
              }
            >
              <Meta
                title="Add Entry in Week View"
                description="In the week, work week and day planner views, you can add calendar entries by clicking and dragging"
              />
            </Card>
          </Col>
          <Col xs={24} lg={8}>
            <Card
              hoverable
              cover={
                <video
                  preload="none"
                  controls
                  src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/videos/add-quick-entry-table-copy.mov"
                />
              }
            >
              <Meta
                title="Add Quick Entry (And Copy Method)"
                description="You can add entries from the top toolbar, faster. Then use the copy function in table and card views to create similar entries"
              />
            </Card>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col xs={24} lg={8}>
            <Card
              hoverable
              cover={
                <video
                  preload="none"
                  controls
                  src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/videos/add-labels.mov"
                />
              }
            >
              <Meta
                title="Add Labels"
                description="Adding labels helps keep information grouped and organized together."
              />
            </Card>
          </Col>
          <Col xs={24} lg={8}>
            <Card
              hoverable
              cover={
                <video
                  preload="none"
                  controls
                  src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/videos/add-note-list.mov"
                />
              }
            >
              <Meta
                title="Add Notes with List"
                description="Create notes and use them as templates for calendar entries."
              />
            </Card>
          </Col>
          <Col xs={24} lg={8}>
            <Card
              hoverable
              cover={
                <video
                  preload="none"
                  controls
                  src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/videos/add-payments.mov"
                />
              }
            >
              <Meta
                title="Add Payment for Budgeting"
                description="Adding payments helps you budget efficiently - make sure you don't overspend and keep a track of all expenses."
              />
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default VideoGrid;
