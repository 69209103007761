import React from "react";
import { Layout } from "antd";
import VideoGrid from "./videoGrid";
const { Content } = Layout;

class GuideContentPage extends React.Component {
  state = {};
  render() {
    return (
      <Content>
        <div className="content">
          <h1>DayViewer Guide &amp; Help</h1>
          <p>
            Navigate the side panel to find the topic you need to learn about or
            to get some help with. Or simply browse the guide to see what
            DayViewer Online Planner is capable of.
          </p>
          <p>
            Below is a list of video examples of specific calendar planner
            features and functions.
          </p>
          <VideoGrid />
        </div>
      </Content>
    );
  }
}

export default GuideContentPage;
